@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  max-width: 1920px;
  margin: 0 auto;
}


.red-scroll {
  --sb-track-color: #e4e7e8;
  --sb-thumb-color: #db242e;
  --sb-size: 4px;
}

.red-scroll::-webkit-scrollbar {
  width: var(--sb-size);
  height: var(--sb-size)
}

.red-scroll::-webkit-scrollbar-track {
  background: var(--sb-track-color);
  border-radius: 1px;
}

.red-scroll::-webkit-scrollbar-thumb {
  background: var(--sb-thumb-color);
  border-radius: 1px;

}

@supports not selector(::-webkit-scrollbar) {
  body {
    scrollbar-color: var(--sb-thumb-color) var(--sb-track-color);
  }
}

.banner-swiper .swiper-pagination-bullet {
  opacity: 1 !important;
  background-color: #ffffff !important;
  width: 20px !important;
  height: 5px !important;
  border-radius: 16px !important;
}

.banner-swiper .swiper-pagination-bullet-active {
  background-color: #e0483e !important;
  width: 20 !important;
  height: 5px !important;
  border-radius: 16px !important;
  -webkit-border-radius: 16px !important;
  -moz-border-radius: 16px !important;
  -ms-border-radius: 16px !important;
  -o-border-radius: 16px !important;
}

.loader {
  width: 20px;
  aspect-ratio: 1;
  border-radius: 50%;
  background: radial-gradient(farthest-side, currentColor 94%, #0000) top/8px 8px no-repeat,
    conic-gradient(#0000 30%, currentColor);
  mask: radial-gradient(farthest-side, #0000 calc(100% - 4px), #000 0);
  -webkit-mask: radial-gradient(farthest-side, #0000 calc(100% - 4px), #000 0);
  animation: l13 1s infinite linear;
}

@keyframes l13 {
  100% {
    transform: rotate(1turn);
    -webkit-transform: rotate(1turn);
    -moz-transform: rotate(1turn);
    -ms-transform: rotate(1turn);
    -o-transform: rotate(1turn);
  }
}

.loader-xl {
  width: 80px;
  aspect-ratio: 1;
  border-radius: 50%;
  background: radial-gradient(farthest-side, currentColor 94%, #0000) top/8px 8px no-repeat,
    conic-gradient(#0000 30%, currentColor);
  mask: radial-gradient(farthest-side, #0000 calc(100% - 8px), #000 0);
  -webkit-mask: radial-gradient(farthest-side, #0000 calc(100% - 8px), #000 0);
  animation: l13 1s infinite linear;
}

/* apple pay */
.applePay {
  display: inline-block;
  -webkit-appearance: -apple-pay-button;
  -apple-pay-button-type: plain;
  /* Use any supported button type. */
  cursor: pointer;
  height: 50px;
  width: 100%;
  margin-top: 20px;
  border-radius: 8px;
}

.applePayBlack {
  -apple-pay-button-style: black;
}

.applePayWhite {
  -apple-pay-button-style: white;
}


@layer components {
  .mainSize {
    @apply w-full max-w-[1440px] mx-auto;
  }

  .subMainSize {
    @apply w-full max-w-[1360px] mx-auto;
  }
}